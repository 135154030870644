fluent-tab-panel.interests > section > div {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
}

fluent-tab-panel.interests fluent-card {
    max-width: 300px;
    color: var(--neutral-foreground-rest);
    margin: 10px;
    width: 40vw;
}

fluent-tab-panel.interests fluent-card img {
    max-width: 300px;
    width: 40vw;
}

fluent-tab-panel.interests fluent-card div {
    padding: 0 10px 10px;
}

fluent-tab-panel.interests fluent-card p {
    overflow-y: auto;
    height: 100px;
}

fluent-tab-panel.interests fluent-card h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}