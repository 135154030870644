@use 'hyperlink';
@use 'headings';
@use 'header';
@use 'interests';

.card {
    border: calc(var(--stroke-width) * 1px) solid var(--neutral-stroke-layer-rest);
    border-radius: calc(var(--layer-corner-radius) * 1px);
    box-shadow: var(--elevation-shadow-card-rest);
}

body {
    display: flex;
    flex-flow: column;
    margin: 8px;
    height: calc(100vh - 16px);
    max-width: 115em;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--neutral-layer-1);
    font-family: var(--body-font);
    font-size: var(--type-ramp-base-font-size);
    font-weight: var(--font-weight);
    font-variation-settings: var(--type-ramp-base-font-variations);
    line-height: var(--type-ramp-base-line-height);
    color: var(--neutral-foreground-rest);
}

div[itemtype="https://schema.org/ContactPoint"] {
    display: inline-block;
    margin: 5px;
}

main {
    margin: 10px;
    flex: 1;
    height: 0%;
}

fluent-tabs {
    height: 100%;
}

fluent-tabs::part(tablist) {
    border-top-right-radius: calc(var(--layer-corner-radius) * 1px);
    border-top-left-radius: calc(var(--layer-corner-radius) * 1px);
    background-color: var(--neutral-layer-2);
    width: 100%;
    margin-bottom: calc(var(--layer-corner-radius) * 1px);
}

fluent-tabs::part(tabpanel) {
    overflow-y: auto;
}