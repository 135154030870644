h1 {
    font-size: var(--type-ramp-plus-4-font-size);
    font-variation-settings: var(--type-ramp-plus-4-font-variations);
    line-height: var(--type-ramp-plus-4-line-height);
}

h2 {
    font-size: var(--type-ramp-plus-3-font-size);
    font-variation-settings: var(--type-ramp-plus-3-font-variations);
    line-height: var(--type-ramp-plus-3-line-height);
}