header {
    padding: 12pt;
    display: flex;
    margin: 10px;
    gap: 0px 12pt;
}

header > img {
    object-fit: cover;
    border-radius: 50%;
    max-width: 135px;
    max-height: 135px;
}

header h1, header h2 {
    margin: 0;
}

header a,
header span {
    display: table;
}